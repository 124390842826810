/****************************
RADIO GROUP
****************************/
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #0f4c81;
  border-color: #0f4c81;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: lighten(#0f4c81, 10);
  border-color: lighten(#0f4c81, 10);
}

.ant-radio-button-wrapper:hover {
  color: #0f4c81;
}

/****************************
SWITCH
****************************/
.ant-switch {
  background-color: #f6322d;
  background-color: #0000003c;
}
.ant-switch-checked {
  background-color: #00dd0f;
}

/****************************
MESSAGE
****************************/

.ant-message {
  margin-left: auto;
  margin-right: auto;
}
.ant-message-notice {
  margin-left: auto;
  margin-right: auto;
}

.ant-message-notice-content {
  max-width: 80%;
}

/****************************
POPCONFIGM
****************************/
.ant-popover-buttons {
  text-align: center;
}

.ant-popover-message-title {
  padding: auto;
}

/****************************
input type checkbox
****************************/
.checkBoxContainer {
  display: block;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input ~ .checkmark {
  background-color: #ccc;
  border: 1px solid #2196f3;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmark:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/****************************
TABLE
****************************/

.ant-table-content {
  display: inline-block;
  width: 100%;
}

.ant-table-small {
  border: 0;
}

.GENERICWITHOUTOBSERVATION {
  .ant-table-row-expand-icon {
    display: none;
  }
}

.APPROVED {
  .checkBoxTable {
    display: none;
  }
}

.PAID {
  .checkBoxTable {
    display: none;
  }
}

.DISAPPROVED {
  .checkBoxTable {
    display: none;
  }
}
/****************************
PAGINATION
****************************/

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #0f4c81;
}

.ant-pagination-prev:not(.ant-pagination-disabled) > a > i > svg,
.ant-pagination-next:not(.ant-pagination-disabled) > a > i > svg {
  color: #888;
  font-weight: bold;

  &:hover {
    color: #0f4c81;
    transform: scale(1.1);
  }
}

.ant-pagination-item {
  a {
    color: #888;
    font-weight: bold;

    &:hover {
      color: #0f4c81;
      transform: scale(1.06);
    }
  }
}
.ant-pagination-item-active,
.ant-pagination-item-active:active,
.ant-pagination-item-active:focus {
  border-color: #0f4c81;

  a {
    color: #0f4c81;
  }

  &:hover {
    // font-weight: bold;
    border-color: #0f4c81;
    background: #0f4c81;

    a {
      color: #fff;
    }
  }
}

form {
  h3 {
    color: #888;
    text-transform: uppercase;
    margin: 15px 8px !important;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
  }

  .ant-row h3 {
    margin: 15px 8px 5px;
  }

  .ant-col-xs-1,
  .ant-col-xs-2,
  .ant-col-xs-3,
  .ant-col-xs-4,
  .ant-col-xs-5,
  .ant-col-xs-6,
  .ant-col-xs-7,
  .ant-col-xs-8,
  .ant-col-xs-9,
  .ant-col-xs-10,
  .ant-col-xs-11,
  .ant-col-xs-12,
  .ant-col-xs-13,
  .ant-col-xs-14,
  .ant-col-xs-15,
  .ant-col-xs-16,
  .ant-col-xs-17,
  .ant-col-xs-18,
  .ant-col-xs-19,
  .ant-col-xs-20,
  .ant-col-xs-21,
  .ant-col-xs-22,
  .ant-col-xs-23,
  .ant-col-xs-24 {
    align-self: center;
  }
}

// CHARTS
.highcharts-color-0 {
  transition: 0s;
  fill: #ddd;

  &:hover {
    fill: #0f4c81;
  }
}
.highcharts-grid-line {
  display: none;
}
.highcharts-credits {
  display: none;
}
.highcharts-legend {
  display: none;
}

.highcharts-axis,
.highcharts-yaxis {
  display: none;
}

.highcharts-yaxis-labels {
  display: none;
}

.highcharts-label {
  color: red;
}

.highcharts-tooltip-box {
  transition: 0s;
  fill: #0f4c81;
  fill-opacity: 0.6;
  stroke-width: 0;
  border-radius: 15px;
}

.highcharts-crosshair-category {
  stroke: none;
}
.highcharts-crosshair-category:hover {
  .highcharts-color-0 {
    fill: #0f4c81;
  }
}
