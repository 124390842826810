#container-images {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000003c;
  cursor: pointer;
  gap: 30px;
}


#container-image-original, #container-image-add {
  display: flex;
  align-items: center;
  justify-content: center;
}

#container-image-original img, #container-image-add img {
  width: 600px;
}

.add-images-button {
  margin-left: 30%;
  margin-bottom: 20px;
}

.principal-img {
  margin-bottom: 20px;
}